import { Tab, Tabs } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { Component } from 'react';
import Config from "../../../../config";
class W06F0001Tabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indexTab: "all",
        }
    }

    renderTab = () => {
        const { funcGetEStatus, tabStatus } = this.props;
        return tabStatus.map(({ EStatus, EStatusName, total }, i) => (
            <Tab
                key={i}
                value={EStatus}
                label={String(Config.lang(EStatusName)) === "Tất cả" ? `${Config.lang("ERP_Tat_ca")} (${total})` : `${Config.lang(EStatusName)} (${total})`}
                onClick={() => funcGetEStatus(EStatus)}
            />
        ));
    };

    handleChanged = (event, indexTab) => {
        this.setState({ indexTab });
    };

    render() {
        const { indexTab } = this.state;
        return (
            <Tabs
                indicatorColor={"primary"}
                textColor={"primary"}
                value={indexTab}
                onChange={this.handleChanged}
            >
                {this.renderTab()}
            </Tabs>
        );
    }
}

W06F0001Tabs.propsTypes = {
    tabStatus: PropTypes.array,
    funcGetEStatus: PropTypes.func
};

export default (W06F0001Tabs);
