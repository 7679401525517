import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Column } from "devextreme-react/data-grid";
import React, { useEffect, useRef, useState } from "react";
import Config from "../../../../config";
import Icons from "../../../common/icons/";
import Modal from "../../../common/modal/modal";
import GridContainer from "../../../grid-container/grid-container";
import { Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { TextBox } from 'devextreme-react';
import { FormLabel as Label } from "@material-ui/core";
import NumberFormatCustom from '../../../common/form-material/numberFormatField';
import { Combo, TextField } from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import { useDispatch } from 'react-redux';
import * as W06F0001Actions from '../../../../redux/W0X/W06F0001/W06F0001_actions';

const useStyles = makeStyles((theme) => ({
    contentCode: {
        width: '100%',
        marginBottom: 15,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    divHeaderTitle: {
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: '0.85rem',
        textTransform: 'uppercase',
        color: '#8F9BB3',
    },
    divHeaderItem: {
        marginLeft: 8,
        fontSize: 16,
        fontWeight: 600,
        fontStyle: 'normal',
    },
    cellPadding: {
        '& .dx-datagrid-content': {
            '& td': {
                paddingTop: '8px !important',
                paddingBottom: '8px !important',
            }
        }
    },
    checkBoxGrid: {
        padding: 0,
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    inputField: {
        transform: 'translateY(-5px)',
        backgroundColor: 'transparent !important',
        marginTop: '0 !important',
    },
    textBoxField: {
        backgroundColor: 'transparent !important',
        '& input': {
            paddingLeft: '0px !important',
            paddingRight: '0px !important'
        }
    }
}))

function W06F0001UpdateInfoCancel(props) {
    const classes = useStyles();
    const { onCloseUpdateInfoCancelModal, currentRowSelected } = props;
    const dispatch = useDispatch();
    const dataGrid = useRef(null);
    const [loading, setLoading] = useState(true);
    const [cbStatus, setCbStatus] = useState([]);
    const oldData = useRef(null);

    const [state, setData] = useState({
        master: { CDate: new Date(), CStatus: "0", CNotes: '' },
        detail: [],
    });

    const setState = (obj) => {
        setData({ ...state, ...obj });
    }

    useEffect(() => {
        getCboStatus();
        loadFormInfoCancel(currentRowSelected.ContractID);
        /* eslint-disable */
    }, [])

    const getCboStatus = () => {
        dispatch(W06F0001Actions.getCboStatus(null, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show(message);
                return false;
            }
            setCbStatus(data);
        }));
    }

    const loadFormInfoCancel = (ContractID) => {
        dispatch(W06F0001Actions.loadFormInfoCancel({ ContractID }, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show(message);
                return false;
            }
            const masterF = { ...master };
            const dataFilter = data[0];
            masterF.CDate = dataFilter.CDate ?? masterF.CDate;
            masterF.CStatus = dataFilter.CStatusID !== '' ? dataFilter.CStatusID : masterF.CStatus;
            masterF.CNotes = dataFilter.CNotes ?? masterF.CNotes;
            setState({
                master: masterF,
                detail: dataFilter.detail
            });
            oldData.current = JSON.parse(JSON.stringify({
                master: {
                    CDate: dataFilter.CDate,
                    CStatus: dataFilter.CStatusID,
                    CNotes: dataFilter.CNotes
                },
                detail: dataFilter.detail
            }));
            setLoading(false);
        }));
    }

    const getCaptions = () => {
        const captions = {
            //master
            CDate: "Ngay_lap",
            CStatus: "Trang_thai",
            CNotes: "Ly_do_huy",
            //detail
            QuantityCancel: "So_luong_huy1",
            DescriptionU: "Ghi_chu",
        };
        return captions;
    }

    const createHistoryDetail = (keyExpr, codeID) => {
        let options = null;
        switch (keyExpr) {
            case "PurchaseItemID":
                options = {
                    codeID: codeID,
                    keyExpr: keyExpr,
                    formID: "W06F0001",
                    mode: ["edit"],
                    // excludeFields: ["IsUpdated"],
                    itemRender: (e, type) => {
                        const { mode, data, dataField, captions } = e;
                        if (mode === "editGrid") {
                            if (type === "description84") return Config.lang(captions[dataField], "vi") + ' Hàng hóa ' + data.InventoryID + ' ' + data.InventoryName;
                            if (type === "description01") return Config.lang(captions[dataField], "en") + ' Inventory ' + data.InventoryID + ' ' + data.InventoryName;
                        }
                    }
                };
                break;
            default:
                break;
        }
        return options;
    }

    const createHistoryMaster = (key, oldValue, newValue) => {
        let temp = {};
        temp.codeID = currentRowSelected.ContractID;
        temp.formID = 'W06F0001';
        temp.linkedTrans = '';
        temp.action = 1;
        temp.description84 = Config.lang(getCaptions()[key], 'vi');
        temp.description01 = Config.lang(getCaptions()[key], 'en');
        temp.oldValue = oldValue;
        temp.newValue = newValue;
        temp.hasURL = false;

        return temp;
    }

    const saveCancel = () => {
        const params = {
            ContractID: currentRowSelected.ContractID,
            ...state.master,
            detail: JSON.stringify(state.detail)
        };
        dispatch(W06F0001Actions.saveInfoCancel(params, async (error, data) => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show(message);
                return false;
            }
            let dataHistory = [];
            const masterKey = Object.keys(state.master);
            masterKey.forEach((value) => {
                if (state.master[value] !== oldData.current.master[value]) {
                    const oldValue = oldData.current.master[value];
                    const newValue = state.master[value];
                    dataHistory.push(createHistoryMaster(value, oldValue, newValue));

                }
            });
            const options = createHistoryDetail("PurchaseItemID", currentRowSelected.ContractID);
            await Config.createSaveHistory(state.detail, oldData.current.detail, getCaptions(), { ...options, ortherData: dataHistory });
            Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
            onCloseUpdateInfoCancelModal();
        }));

    }

    const onChangeInputDetail = (columnName = "", e, currentRow = {}) => {
        if (_.isEmpty(currentRow) || _.isEmpty(columnName)) return;
        currentRow[columnName] = e;
        // setDisabled(false);
    }


    const renderCancelInfo = (e) => {
        const { data } = e.row;
        const { InventoryID = "", InventoryName = "", PackingMethodName = "", PackingTypeName = "",
            UnitID = "", DescriptionU = "", QuantityCancel = 0, TotalQuantity = 0, Quantity = 0 } = data;
        // const quantityCancelValue = Number(TotalQuantity) - Number(Quantity);
        return (
            <Row>
                <Col xs={12} sm={6} md={6} lg={6} style={{ minHeight: 70 }}>
                    <div style={{ display: 'flex', width: '100%', marginBottom: 15 }}>
                        <span style={{ width: '32%', marginRight: 5 }}><Label style={{ fontWeight: 600 }}>{Config.lang("Hang_hoa")}:</Label></span>
                        <span className={classes.ellipsis} style={{ width: '68%' }}>{`${InventoryID} - ${InventoryName}`}</span>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginBottom: 15 }}>
                        <span style={{ width: '32%', marginRight: 5 }}><Label style={{ fontWeight: 600 }}>{Config.lang("Quy_cach_dong_goi")}:</Label></span>
                        <span className={classes.ellipsis} style={{ width: '68%' }}>{`${PackingMethodName || ""}`}</span>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginBottom: 15 }}>
                        <span style={{ width: '32%', marginRight: 5 }}><Label style={{ fontWeight: 600 }}>{Config.lang("Loai_bao_dong_goi")}:</Label></span>
                        <span className={classes.ellipsis} style={{ width: '68%' }}>{`${PackingTypeName || ""}`}</span>
                    </div>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <span style={{ width: '32%', marginRight: 5 }}>{Config.lang("Don_vi_tinh")}:</span>
                        <span className={classes.ellipsis} style={{ width: '68%' }}>{`${UnitID}`}</span>
                    </div>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} style={{ minHeight: 70 }}>
                    <div style={{ display: 'flex', width: '100%', marginBottom: 15 }}>
                        <span style={{ width: '36%' }}>{Config.lang("Tong_so_luong")}:</span>
                        <span className={classes.ellipsis} style={{ width: '64%' }}>{`${Config.numberFormat(TotalQuantity)}`}</span>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginBottom: 15 }}>
                        <span style={{ width: '36%' }}>{Config.lang("So_luong_da_thuc_hien")}:</span>
                        <span className={classes.ellipsis} style={{ width: '64%' }}>{`${Quantity ?? 0}`}</span>
                    </div>
                    <div style={{ display: 'flex', width: '100%', marginBottom: 15 }}>
                        <span style={{ width: '36%' }}>{Config.lang("So_luong_huy1")}:</span>
                        <span className={classes.ellipsis} style={{ width: '64%' }}>
                            <NumberFormatCustom
                                fullWidth
                                margin={"none"}
                                isNumericString={true}
                                thousandSeparator={true}
                                value={QuantityCancel}
                                className={classes.inputField}
                                onChange={(e) => onChangeInputDetail("QuantityCancel", _.toNumber(e), data)}
                            />
                        </span>
                    </div>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <span style={{ width: '36%' }}>{Config.lang("Ghi_chu")}:</span>
                        <span className={classes.ellipsis} style={{ width: '64%' }}>
                            <TextBox
                                name={DescriptionU}
                                className={classes.textBoxField}
                                stylingMode={"outlined"}
                                onChange={(e) => onChangeInputDetail("DescriptionU", e.event.target.value, data)}
                                defaultValue={DescriptionU} />
                        </span>
                    </div>
                </Col>
            </Row>
        )
    }

    const onHandleChangeMaster = (key, e) => {
        const data = { ...master };
        switch (key) {
            case 'CDate':
            case 'CStatus':
                data[key] = e.value;
                setState({ master: data });
                break;
            case 'CNotes':
                data[key] = e.target.value;
                setState({ master: data });
                break;
            default:
                break;
        }
        // setDisabled(false);
    }

    const { master, detail } = state;

    return (
        <div>
            <Modal
                open={true}
                maxWidth={"md"}
                fullWidth={true}
                position={"center"}
            >
                <Modal.Title disableTypography>
                    <div className={"display_row align-center align-between flex-wrap"} style={{ width: "100%" }}>
                        <div className={"display_row align-center"}>
                            <Typography variant={"h6"} className={"mgr10 text-uppercase"}>{Config.lang("Cap_nhat_thong_tin_huy")}</Typography>
                        </div>
                        <div>
                            <IconButton
                                // disabled={disabled}
                                aria-label={"save"}
                                size={"small"}
                                className={"mgr10"}
                                onClick={saveCancel}
                            >
                                <Icons name={"save_filled"} />
                            </IconButton>
                            <IconButton
                                aria-label={"close"}
                                size={"small"}
                                onClick={() => onCloseUpdateInfoCancelModal()}
                            >
                                <Icons name={"cancel_filled"} />
                            </IconButton>
                        </div>
                    </div>
                </Modal.Title>
                <Modal.Content>
                    <Row>
                        <Col md={6}>
                            <DateBoxPicker
                                height={30}
                                shrink={true}
                                useMaskBehavior={true}
                                showClearButton={true}
                                width={"100%"}
                                margin={"normal"}
                                stylingMode={"underlined"}
                                label={Config.lang("Ngay_lap")}
                                InputLabelProps={{
                                    shrink: true,
                                    style: { marginTop: -7 }
                                }}
                                disabled={loading}
                                value={master.CDate}
                                required={true}
                                onValueChanged={e => onHandleChangeMaster("CDate", e)}
                            />
                        </Col>
                        <Col md={6}>
                            <Combo
                                shrink={true}
                                showClearButton={true}
                                margin={"normal"}
                                valueExpr={"CStatusID"}
                                stylingMode={"underlined"}
                                displayExpr={"CStatusName"}
                                label={Config.lang("Trang_thai")}
                                value={master.CStatus}
                                disabled={loading}
                                dataSource={cbStatus}
                                onValueChanged={(e) => onHandleChangeMaster("CStatus", e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <TextField
                                fullWidth
                                margin={"normal"}
                                variant={"standard"}
                                label={Config.lang("Ly_do_huy")}
                                disabled={loading}
                                value={master.CNotes}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onHandleChangeMaster("CNotes", e)}
                            />
                        </Col>
                    </Row>
                    <React.Fragment>
                        <GridContainer
                            reference={ref => dataGrid.current = ref}
                            typePaging={"default"}
                            elementAttr={{ style: 'margin-top: 30px' }}
                            itemPerPage={3}
                            skipPerPage={0}
                            height={380}
                            showBorders={true}
                            showRowLines={true}
                            pagerFullScreen={false}
                            showColumnLines={false}
                            showColumnHeaders={false}
                            dataSource={detail}
                            loading={loading}
                        >
                            <Column cellRender={renderCancelInfo} width={"100%"} />
                        </GridContainer>
                    </React.Fragment>

                </Modal.Content>
            </Modal>
        </div>
    );
}

export default W06F0001UpdateInfoCancel;